<template>
  <div class="cms-divider-cta" v-editable="editable" v-if="text">
    <a :href="getExternalLink" v-if="isExternalLink" :target="target">
      <button class="cta-link" v-if="isTextCTA">
        {{ text }}
      </button>
      <AButton
        v-else
        :variant="outlined ? 'outlined' : 'contained'"
        :color="isSecondary ? 'secondary' : 'primary'"
      >{{ text }}</AButton>
    </a>

    <router-link :to="localizedRoute(getStoryLink)" v-else-if="isLinkTypeStory">
      <button class="cta-link" v-if="isTextCTA">
        {{ text }}
      </button>
      <AButton
        v-else
        :variant="outlined ? 'outlined' : 'contained'"
        :color="isSecondary ? 'secondary' : 'primary'"
      >
        {{ text }}
      </AButton>
    </router-link>

    <router-link
      :to="localizedRoute(getDynamicLink)"
      v-else-if="isInternalLink"
    >
      <button class="cta-link" v-if="isTextCTA">
        {{ text }}
      </button>
      <AButton
        v-else
        :variant="outlined ? 'outlined' : 'contained'"
        :color="isSecondary ? 'secondary' : 'primary'"
      >
        {{ text }}
      </AButton>
    </router-link>
  </div>
</template>

<script>
import AButton from 'common/components/atoms/a-button';
import config from 'config';
import { mapGetters } from 'vuex';

export default {
  name: 'CTA',
  props: {
    editable: {
      type: Object,
      default: () => {}
    },
    outlined: {
      type: Boolean,
      default: false
    },
    text: {
      type: String,
      default: ''
    },
    link: {
      type: String,
      default: ''
    },
    linkType: {
      type: String,
      default: ''
    },
    layoutOption: {
      type: String,
      default: ''
    },
    target: {
      type: String,
      default: '_self'
    }
  },
  components: {
    AButton
  },
  computed: {
    ...mapGetters(['getCurrentStoreView']),
    isSecondary () {
      return this.layoutOption === 'secondary';
    },
    isTextCTA () {
      return this.layoutOption === 'text';
    },
    isLinkTypeStory () {
      return this.linkType === 'story';
    },
    isInternalLink () {
      return this.link.includes('{store}');
    },
    isExternalLink () {
      return !this.isLinkTypeStory && !this.isInternalLink;
    },
    isLocalStory () {
      return this.link.includes(`/${config?.storyblok?.localFolder}/`);
    },
    getStoryLink () {
      const localFolder = `/${config?.storyblok?.localFolder}/`;

      if (this.isLocalStory) {
        const result = this.link?.split(localFolder);
        return `${localFolder}${result[1]}`;
      }

      return this.link;
    },
    getDynamicLink () {
      return this.link.replace('{store}', '');
    },
    getExternalLink () {
      return this.link;
    }
  }
};
</script>

<style lang="scss" scoped>
@import "~@storefront-ui/shared/styles/helpers/breakpoints";

.cms-divider-cta {
  display: flex;
  justify-content: center;
  padding: 0 20px;

  > a {
    width: 100%;
    @include for-desktop {
      width: auto;
    }
  }

  ::v-deep .sf-button {
    --button-display: inline-block;
    width: 100%;
  }
}
</style>
