<template>
  <div @click="handleCardClick">
    <component
      :is="getTag"
      v-editable="editable"
      :to="ctaLabel ? getRedirectLink : ''"
      :href="getRedirectLink"
      :target="ctaLabel ? getCTA.target : ''"
      class="cms-card"
      :style="getStyles"
    >
      <div
        v-if="image && image.filename"
        class="card-image"
      >
        <MImage
          :src="getLazyLoadImage"
          :alt="getAltText(image)"
          :backdrop="false"
          :picture-breakpoint="760"
          :lazy="lazy"
        />
        <div class="card-content">
          <h3
            v-if="title"
            class="card-title"
          >
            {{ title }}
          </h3>
          <div
            v-if="ctaLabel && !hideCta"
            @click="handleCtaClick"
          >
            <CTA
              :editable="editable"
              :text="ctaLabel"
              :link="ctaLink"
              :link-type="ctaLinkType"
              :target="getCTA.target"
              :layout-option="ctaLayoutOption"
            />
          </div>
        </div>
      </div>
    </component>
  </div>
</template>

<script>
import ImageResize from '../mixins/ImageResize';
import MImage from 'src/common/components/molecules/m-image.vue';
import CTA from 'src/modules/storyblok/components/CTA';
import config from 'config';
import EventBus from '@vue-storefront/core/compatibility/plugins/event-bus';

export default {
  name: 'OverlayCard',
  components: {
    MImage,
    CTA
  },
  mixins: [ImageResize],
  props: {
    image: {
      type: Object,
      default: () => {}
    },
    observeIndividualItem: {
      type: Boolean,
      default: false
    },
    title: {
      type: String,
      default: ''
    },
    textColor: {
      type: String,
      default: ''
    },
    buttonOption: {
      type: String,
      default: ''
    },
    noGap: {
      type: Boolean,
      default: false
    },
    editable: {
      type: Object,
      default: () => {}
    },
    columnsLength: {
      type: String,
      default: '0'
    },
    lazy: {
      type: Boolean,
      default: true
    },
    position: {
      type: Number,
      default: 1
    },
    parentBlockId: {
      type: String,
      default: ''
    },
    cta: {
      type: Array,
      default: () => []
    }
  },
  computed: {
    getStyles () {
      return {
        '--text-color': this.textColor
      };
    },
    getDesktopImage () {
      if (this.columnsLength === '2') {
        return this.getImage('625x0');
      } else if (this.columnsLength === '3') {
        return this.getImage('407x0');
      } else if (this.columnsLength === '4') {
        return this.getImage('300x0');
      }

      return this.getImage('625x0');
    },
    getMobileImage () {
      if (this.columnsLength === '2') {
        return this.getImage('350x0');
      }
      if (this.columnsLength === '3') {
        return this.getImage('220x0');
      }
      if (this.columnsLength === '4') {
        return this.getImage('170x0');
      }
      return this.getImage('350x0');
    },
    getCTA () {
      let oldData = null;
      return this?.cta?.[0] || oldData;
    },
    ctaLink () {
      const linkObj = this.getCTA?.link || {};
      return linkObj?.url || linkObj?.cached_url;
    },
    ctaLabel () {
      return this.getCTA?.text || '';
    },
    ctaLayoutOption () {
      return this.getCTA?.layout_option || '';
    },
    ctaLinkType () {
      return this.getCTA?.link?.linktype || '';
    },
    hideCta () {
      return this.getCTA?.hide_cta || false;
    },
    isLinkTypeStory () {
      return this.ctaLinkType === 'story';
    },
    isInternalLink () {
      return this.ctaLink?.includes('{store}');
    },
    isExternalLink () {
      return !this.isLinkTypeStory && !this.isInternalLink;
    },
    isLocalStory () {
      return this.ctaLink?.includes(`/${config?.storyblok?.localFolder}/`);
    },
    getStoryLink () {
      const localFolder = `/${config?.storyblok?.localFolder}/`;

      if (this.isLocalStory) {
        const result = this.ctaLink?.split(localFolder);
        return `${localFolder}${result[1]}`;
      }
      return this.ctaLink;
    },
    getDynamicLink () {
      return this.ctaLink?.replace('{store}', '');
    },
    getExternalLink () {
      return this.ctaLink;
    },
    getRedirectLink () {
      if (this.isExternalLink) {
        return this.getExternalLink;
      }
      if (this.isLinkTypeStory) {
        return this.localizedRoute(this.getStoryLink);
      }
      if (this.isInternalLink) {
        return this.localizedRoute(this.getDynamicLink)
      }
      return ''
    },
    getTag () {
      if (this.ctaLabel) {
        return this.isExternalLink ? 'a' : 'router-link';
      }
      return 'div';
    }
  },
  mounted () {
    if (!this.observeIndividualItem) return;

    this.observer = new IntersectionObserver(
      (entries) => {
        const blockEl = entries[0];
        if (blockEl.isIntersecting) {
          EventBus.$emit('home-block-promo-view', {
            id: this.parentBlockId,
            name: this.title,
            creative: null,
            position: null
          });
          this.observer.disconnect();
        }
      },
      {
        threshold: 0.7
      }
    );

    setTimeout(() => {
      this.observer.observe(this.$el);
    }, 500);
  },
  destroyed () {
    if (!this.observeIndividualItem) return;
    this.observer.disconnect();
  },
  methods: {
    handleCardClick () {
      if (!this.ctaLabel) {
        return
      }
      EventBus.$emit('home-block-item-clicked', {
        id: this.parentBlockId,
        name: this.title,
        creative: this.image.alt,
        position: this.position
      });
      EventBus.$emit('homepage_click', {
        URL: this.ctaLink,
        homepage_id: this.parentBlockId,
        name: this.title,
        position: this.position
      });
    },
    handleCtaClick (event) {
      event.stopPropagation();
      this.handleCardClick()
    },
    getAltText (image) {
      return image?.alt || this.getFilenameFromUrl(image)
    }
  }
};
</script>

<style lang="scss" scoped>
@import "~@storefront-ui/shared/styles/helpers/breakpoints";
@import "src/common/assets/css/storyblok/lazyload";

.cms-card {
  margin-bottom: var(--cms-card-margin-bottom) !important;
  display: block;
  text-align: center;
  background-color: transparent;
  padding: var(--divider-text-only-padding, 0);
  position: relative;
  .card-image {
    display: flex;
    img,
    picture {
      object-fit: cover;
      margin: 0 auto;
      max-width: 100%;
    }
  }

  .card-content {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translateX(-50%) translateY(-50%);
    .card-title {
      margin: 0;
      margin-bottom: 40px;
      color: var(--text-color, var(--neutral-900));
    }
  }

  ::v-deep .sf-button {
    --button-display: inline-block;
  }

  ::v-deep .cms-divider-cta {
    padding: 0;
    & > a {
      width: 100%;
    }
  }

  ::v-deep .m-image {
    @include lazyload-image;
    padding-top: initial;
    img {
      position: relative;
    }
  }

  ::v-deep .loaded {
    @include lazyload-image-loaded;
  }
}
</style>
