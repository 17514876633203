import { resizeImage, getImageDimensions } from '../helpers'

export default {
  computed: {
    reducedImageWidth () {
      const imagePath = this.image?.filename
      if (!imagePath) return 0

      const dimensions = getImageDimensions(imagePath)
      const width = Number(dimensions[0])
      const reductionFactor = 10

      return width - Math.floor(width * (reductionFactor / 100))
    },
    getMobileImage () {
      return this.getImage(`${this.reducedImageWidth}x0`, this.mobileImage || null)
    },
    getLazyLoadImage () {
      return {
        desktop: {
          url: this.getDesktopImage
        },
        mobile: {
          url: this.getMobileImage
        }
      }
    }
  },
  methods: {
    getImage (dimension, image = {}) {
      let newImage = this.image || image
      return resizeImage(newImage, dimension)
    },
    getFilenameFromUrl (image) {
      const parts = image?.filename?.split('/');
      const filename = parts[parts.length - 1];
      return filename?.replace(/\.(jpg|jpeg|png|webp)$/i, '');
    }
  }
}
